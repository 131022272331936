<template>
  <div class="grid grid4">
    <router-link
      to="/app-users"
    >
      <value-card 
        background-class="bg-info"
        label="Aantal gebruikers laatste week"
        :value="statistics.users?.usersAccessedInLastWeek ?? 0"
        font-awesome-icon-name="calendar"
      />
    </router-link>

    <router-link
      to="/app-users"
    >
      <value-card 
        background-class="bg-secondary"
        label="Aantal gebruikers laatste maand"
        :value="statistics.users?.usersAccessedInLastThirtyDays ?? 0"
        font-awesome-icon-name="calendar"
      />
    </router-link>

    <router-link
      to="/app-users"
    >
      <value-card 
        background-class="bg-success"
        label="Actieve gebruikers"
        :value="statistics.users?.activeCount ?? 0"
        font-awesome-icon-name="user-plus"
      />
    </router-link>

    <router-link
      to="/app-users"
    >
    <value-card 
      background-class="bg-dark"
      label="Nieuw gebruikers laatste maand"
      :value="statistics.users?.newUsersInLast30days ?? 0"
      font-awesome-icon-name="user-plus"
    />
    </router-link>

    <router-link
      to="/app-users"
    >
    <value-card 
      background-class="bg-success"
      label="Totaal aantal gebruikers"
      :value="statistics.users?.count ?? 0"
      font-awesome-icon-name="users"
    />
    </router-link>

    <router-link
      to="/news"
    >
    <value-card 
      background-class="bg-gray-800"
      label="Aantal nieuwsberichten"
      :value="statistics.news?.count ?? 0"
      font-awesome-icon-name="newspaper"
    />
    </router-link>

    <router-link
      to="/events"
    >
      <value-card 
        background-class="bg-warning"
        label="Aantal evenementen"
        :value="statistics.events?.count ?? 0"
        font-awesome-icon-name="clock"
      />
    </router-link>

    <router-link
      to="/events"
    >
    <value-card 
      background-class="bg-gray-400"
      label="Aankomende evenementen"
      :value="statistics.events?.futureEventCount ?? 0"
      font-awesome-icon-name="clock"
    />
    </router-link>

    <router-link
      v-if="companyModuleIsActive"
      to="/company"
    >
      <value-card
        background-class="bg-gray-600"
        label="Aantal bedrijven"
        :value="statistics.companies?.count ?? 0"
        font-awesome-icon-name="building"
      />
    </router-link>

    <router-link
      v-if="chatModuleIsActive"
      to="/app-users"
    >
      <value-card
        background-class="bg-info"
        label="Aantal chats"
        :value="statistics.chats?.count ?? 0"
        font-awesome-icon-name="comment"
      />
    </router-link>

    <router-link
      v-if="pinboardModuleIsActive"
      to="/app-users"
    >
      <multi-value-card
        background-class="bg-gray-400"
        :labels="[ 'Vraag', 'Aanbod' ]"
        :values="[ statistics.pinboard?.requests ?? 0, statistics.pinboard?.offers ?? 0]"
        font-awesome-icon-name="thumbtack"
      />
    </router-link>
  </div>
</template>

<script>
import MultiValueCard from '@/components/Dashboard/MultiValueCard.vue';
import ValueCard from '@/components/Dashboard/ValueCard.vue';
import { mapGetters } from 'vuex';

import StatisticsService from '@/domain/services/statistics';

import { moduleIsActive } from '@/helpers/module';

export default {
  components: {
    MultiValueCard,
    ValueCard
  },

  data() {
    return {
      statisticsData: null,

      chatModuleIsActive: false,
      companyModuleIsActive: false,
      pinboardModuleIsActive: false,
      pollModuleIsActive: false,
    };
  },

  computed: {
    ...mapGetters('account', [
      'currentOrganization'
    ]),
    statistics() {
      return this.statisticsData ?? {}
    }
  },

  async mounted() {
    this.chatModuleIsActive = await moduleIsActive("Chat");
    this.companyModuleIsActive = await moduleIsActive("CompanyPage");
    this.pinboardModuleIsActive = await moduleIsActive("Pinboard");
    this.pollModuleIsActive = await moduleIsActive("Poll");

    if (this.currentOrganization?.code == null) return;
    this.getStatistics();
  },

  methods: {
    async getStatistics() {
      this.statisticsData = await StatisticsService.getStatisticsAsync();
    }
  }
};
</script>

<style scoped>
.col-4 {
  flex-direction: column;
  display: flex;
  gap: 20px;
}
</style>